<template>
    <v-container class="black--text py-16 d-flex min-h-100-lg" style="align-items: center; justify-content: center;">
         <!-- DESKTOP -->
         <v-container fluid class="d-none d-lg-block">
            <v-row justify="center">
                <v-col cols="12" class="text-h4 text-center text-uppercase">
                    {{$t('globals.frequent_questions')}}
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" lg="8">
                    
                    <v-expansion-panels v-model="this.openPanels" accordion light>
                        <transition-group name="fade" tag="div" style="width: 100%;">
                        <v-col cols="12" md="12" class="text-center elevation-0" v-for="(item,i) in getQuestions" :key="i" v-show="openPanel === null || openPanel === i">
                                <v-expansion-panel 
                                    class="mt-4 rounded-lg elevation-0"
                                    @click="togglePanel(i)"
                                >
                                    <v-expansion-panel-header class="font-weight-black text-body-1">
                                        {{item.title}}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card class="elevation-0" v-if="item.content.type == 'text'">
                                            <v-card-text class="text-left text-body-1" v-for="(line, index) in item.content.text" :key="index + 9000">
                                                {{line}}
                                            </v-card-text>                                    
                                        </v-card>
                                        <v-card class="elevation-0" v-if="item.content.type == 'bullet'">
                                            <v-card-text class="text-left text-body-1">
                                                <ul>
                                                    <li v-for="(line, index) in item.content.text" :key="index">
                                                        {{line}}
                                                    </li>
                                                </ul>
                                            </v-card-text>                                    
                                        </v-card>
                                    </v-expansion-panel-content>
                                    <div style="background-color: grey; height: .5px; width: 100%;"></div>
                                </v-expansion-panel>
                        </v-col>
                    </transition-group>
                    </v-expansion-panels>
                
                    <v-pagination v-model="currentPage" :length="pageCount" @input="handlePageChange" circle class="mt-8"></v-pagination>
                </v-col>
            </v-row>
        </v-container>
         <!-- DESKTOP -->
         <!-- MOBILE -->
         <v-container fluid class="d-lg-none mb-n12">
            <v-row justify="center">
                <v-col cols="12" class="text-center text-uppercase font-weight-bold" style="font-size: 20px;">
                    {{$t('globals.frequent_questions')}}
                </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
                <v-expansion-panels accordion light class="ma-0 pa-0">
                    <v-col style="width: 100%;" cols="12" md="6" class="ma-0 pa-0 text-center elevation-0" v-for="(item,i) in getQuestions" :key="i">
                            <v-expansion-panel 
                                class="mt-4 rounded-lg elevation-0"
                            >
                                <v-expansion-panel-header class="font-weight-bold px-0" style="font-size: 15px;min-height: unset !important;">
                                    {{item.title}}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-card class="elevation-0" v-if="item.content.type == 'text'">
                                        <v-card-text class="text-left text-body-1 px-0 pt-0" v-for="(line, index) in item.content.text" :key="index + 9000" style="font-size: 13px !important; line-height: 1.4;">
                                            {{line}}
                                        </v-card-text>                                    
                                    </v-card>
                                    <v-card class="elevation-0" v-if="item.content.type == 'bullet'">
                                        <v-card-text class="text-left text-body-1 px-0 pt-0" style="font-size: 13px !important; line-height: 1.4;">
                                            <ul>
                                                <li v-for="(line, index) in item.content.text" :key="index">
                                                    {{line}}
                                                </li>
                                            </ul>
                                        </v-card-text>                                    
                                    </v-card>
                                </v-expansion-panel-content>
                                <div style="background-color: grey; height: .5px; width: 100%;"></div>
                            </v-expansion-panel>
                    </v-col>
                </v-expansion-panels>
                <v-pagination v-model="currentPage" :length="pageCount" @input="handlePageChange" circle class="mt-8" style='width: 100%;'></v-pagination>
            </v-row>
        </v-container>
         <!-- MOBILE -->
    </v-container>
</template>
<script>
export default {
    name: "Questions",
    data() {
        return {
            currentPage: 1,
            questionsPerPage: 4,
            openPanel: null,
            openPanels: [],
        };
    },
    computed: {
        pageCount() {
            return Math.ceil(this.$t('questions.items').length / this.questionsPerPage);
        },
        getQuestions() {
            let start = (this.currentPage - 1) * this.questionsPerPage;
            let end = start + this.questionsPerPage;
            return this.$t('questions.items').slice(start, end);
        }
    },
    methods: {
        togglePanel(index) {
            if (this.openPanel === index) {
                this.openPanel = null;  // Fechar o painel se for o mesmo que está aberto
            } else {
                this.openPanel = index;  // Abrir o novo painel e fechar os outros
            }
        },
        handlePageChange() {
            this.openPanel = null;
            this.openPanels = [];
        },
    }
}
</script>
<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s, transform 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
    transform: translateY(-20px);
}
.fixed-height-panel {
    min-height: 200px; /* Ajuste este valor conforme necessário */
}
.v-expansion-panel::before {
    box-shadow: none !important;
}
@media screen and (max-width: 1263px) {
    .v-expansion-panel-content__wrap {
        padding: 0 !important;
    }
}
</style>