<template>
  <div >
    <v-container v-if="isScreen" class="fill-height py-16 d-flex min-h-100" style="align-items: center; justify-content: center;position: relative;">
        <div data-scroll-in-section class="d-none d-lg-flex">
          <div v-for="item in getCarrossel" :key="item.id" class="item pa-0">
            <div class="card">
              <div class="card-content">
                <h2 class="card-header text-uppercase text-h4 font-weight-bold">{{ item.title }}</h2>
                <h3 class="card-subheader mt-4 text-uppercase font-weight-bold text-h5 white--text"  v-html="item.subtitle"></h3>
                <p class="card-text mt-4 text-body-1 white--text">{{ item.text }}</p>
                <!-- O botão será exibido apenas se item.btn for verdadeiro -->
                <v-card-actions v-if="item.btn != null">
                    <v-btn small @click="$emit(item.btn.click)" light class="font-weight-bold mt-12 neonText" rounded>{{item.btn.label}}</v-btn>
                </v-card-actions>
              </div>
              <div class="card-image">
                <img :src="packImage(item.img)" alt="" />
              </div>
            </div>
          </div>
          
        </div>
        <div class="progress-bar-container d-none d-lg-block">
          <div class="progress-bar" ref="progressBar"></div>
        </div>
    </v-container>
    <!-- MOBILE -->
      <v-container v-else class="fill-height" fluid>
        <v-card v-for="(item,i) in getCarrossel" :key="i" class="elevation-0 transparent">
          <v-card-text style="padding: 12px;">
              <v-row no-gutters align="center">
                  <v-col cols="12"  >
                      <v-card class="elevation-0 transparent text-center">
                          <v-card-title v-if="i == 0" class="text-uppercase" style="padding: 0; font-size: 24px; font-weight: 500; justify-content: center;">
                            {{item.title}}
                          </v-card-title>
                          <v-card-subtitle class="mt-6 text-uppercase font-weight-bold white--text mb-2 card-subheader" style="padding: 0; font-size: 17px;" v-html="item.subtitle"></v-card-subtitle>
                      </v-card>
                  </v-col>
                  <v-col cols="12" align="center">
                      <v-card style="border-radius: 13px;">
                          <v-img :src="packImage(item.img)" contain/>
                      </v-card>
                  </v-col>
              </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    <!-- MOBILE -->
  </div>
</template>
<script>
export default {
    name: "WhatFind",
    data() {
        return {
            scrollingHorizontally: false,
            scrollToFlag: true,
            progressBarWidth: 0,
            lastScrollPercentage: 0,
        }
    },
    mounted() {
      this.$parent.$on('update-progress', this.updateProgressBar);
    },
    methods: {
      packImage(i){
        return require('@/assets/backgrounds/' + i);
      },
      updateProgressBar(scrolledPercentage) {
        const progressBarElement = this.$refs.progressBar;
        if (progressBarElement && (this.lastScrollPercentage - scrolledPercentage) < 70) {
          console.log('scrolledPercentage', scrolledPercentage);
          progressBarElement.style.width = `${scrolledPercentage}%`;
          this.lastScrollPercentage = scrolledPercentage;
        }
        
      }
    },
    computed:{
      isScreen(){
        return window.innerWidth >= 768
      },
      getCarrossel(){
        let carrossel = this.$t('what_find.carrossel')

        return carrossel;
      }
    },
    beforeDestroy() {
      // É uma boa prática remover o listener quando o componente for destruído
      this.$parent.$off('update-progress', this.updateProgressBar);
    }
}
</script>
<style>
html{
    overflow: auto;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
}

.scroll-container {
  display: flex;
  align-items: center;
  overflow-x: auto;
  width: 100%;
  height: 90vh;
  margin-top: 5vh;
  margin-bottom: 5vh;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroll-container::-webkit-scrollbar {
  display: none;
}

.item {
  min-width: 600px;
  flex-shrink: 0;
  padding: 16px;
  margin-right: 80px;
}

.item:last-child {
  margin-right: 80px;
}

.card {
  display: flex;
  color: white;
  overflow: visible;
}

.card-content {
  padding: 40px;
  padding-left: 0;
  padding-right: 60px;
  width: 550px;
}

.card-btn {
  padding: 10px 20px;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.card-btn:hover {
  background-color: #0056b3;
}

.card-subheader span{
  text-transform: none;
}

.card-image {
  min-width: 300px;
}

.card-image img {
  display: block;
  width: 600px;
  height: 400px !important;
  height: auto;
  object-fit: cover;
  border-radius: 20px;
}

.progress-bar-container {
  width: 70%;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  position: absolute;
  bottom: calc(50% - 260px);
}
.progress-bar {
  height: 100%;
  background-color: #FFFFFF;
  width: 0%; /* Iniciar sem preenchimento */
  border-radius: 20px;
}
.v-carousel__controls{
    background: transparent !important;
}

@media screen and (max-width: 1263px){
  section.afterHorizontal{
    margin-top: 0 !important;
  } 
}

</style>