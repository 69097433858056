import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../Layout.vue'
import FatherView from '../views/FatherView.vue'
import ThankYouForm from '../views/ThankYouForm.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/pt'
  },
  {
    path: '/:lang',
    name: 'home',
    component: Layout,
    children: [
      {
        path: '/:lang',
        component: FatherView
      },
      {
        path: '/:lang/thankyouform',
        component: ThankYouForm
      },
      {
        path: '/:lang/politica-de-privacidade',
        component: FatherView
      },
      {
        path: '/:lang/privacy-policy',
        component: FatherView
      },
      {
        path: '/:lang/termos-condicoes',
        component: FatherView
      },
      {
        path: '/:lang/terms-conditions',
        component: FatherView
      },
      
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
